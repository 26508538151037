<template>
   <section class="socials">
     <ul>
       <li class="socials-icon"><a href="https://github.com/yormancast/three-d" target="_blank"><img src="@/assets/img/profile.jpg" alt="profile"></a></li>
       <li class="socials-icon"><a href="https://github.com/yormancast" target="_blank"><img style="background-color: #fff" src="@/assets/img/icons/iconmonstr-github-1.svg" alt="profile"></a></li>
       <li class="socials-icon"><a href="https://www.linkedin.com/in/yormancastellanos/?locale=en_US" target="_blank"><img style="background-color: #fff;" src="@/assets/img/icons/iconmonstr-linkedin-3.svg" alt="profile"></a></li>
     </ul>
   </section>
</template>

<script>
export default {
  name: 'socials'
};
</script>
