<template>
  <three-d/>
</template>

<script>
import threeD from '@/components/threeD/threeD.vue'

export default {
  name: 'App',
  components: {
    threeD
  }
}
</script>
